import request from '@/utils/request'
// 获取分类列表
export function queryGoodsType () {
    return request({
        url: '/shd-operation/lrGoodsClassify/queryGoodsType',
        method: 'get'
    })
}
//获取门店列表
export function listOfPage () {
    return request({
        url: '/goods-service/store/listOfPage',
        method: 'get'
    })
}

//删除
export function delGoodsFreshInfo (id) {
    return request({
        url: `/shd-operation/lrGoodsInfo/delGoodsFreshInfo?id=${id}`,
        method: 'delete'
    })
}
// 上下架请求 `/shd-operation/lrGoodsInfo/upAndDownGoods?id=${row.goodsId}&status=${status}`;
export function upAndDownGoods (data) {
    return request({
        url: `/shd-operation/lrGoodsInfo/upAndDownGoods?id=${data.id}&status=${data.status}`,
        method: 'get'
    })
}
//数据初始化
export function queryGoodsList (data) {
    return request({
        url: '/shd-operation/lrGoodsInfo/queryGoodsList',
        method: 'post',
        data
    })
}